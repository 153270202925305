import React from "react";
import { useAppContext } from "../../context/AppContext";
import AdminDashboard from "./AdminDashboard";
import Home from "./Home";

function HomePage() {
  const { user } = useAppContext();
  return <Home />;
}

export default HomePage;
