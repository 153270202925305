import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useAppContext } from "../../../context/AppContext";

function LineGraphForGroup({ data, title }) {
  const { colors } = useAppContext();
  return (
    <div className={`${colors.cardColor} p-1 container w-full mt-6`}>
      <div className="mx-10 text-xl text-vold mb-2">{title}</div>
      <ResponsiveContainer width={"100%"} height={300}>
        <LineChart
          // width={1024}
          height={600}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="4 4" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip contentStyle={{ color: "red" }} />
          <Legend />

          <Line
            type="monotone"
            name="Highest price"
            dataKey="highestPrice"
            stroke="green"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            name="Lowest price"
            dataKey="lowestPrice"
            stroke="orange"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>

      <div className="mx-10 text-xl text-vold mb-2">Return Percentage</div>
      <ResponsiveContainer width={"100%"} height={300}>
        <LineChart
          // width={1024}
          height={600}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="4 4" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip contentStyle={{ color: "red" }} />
          <Legend />
          <Line
            type="monotone"
            dataKey="avgReturn"
            name="Average Return"
            stroke="#8884d8"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            name="Highest %"
            dataKey="highestPercentage"
            stroke="#82ca9d"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default LineGraphForGroup;
