import React, { useState } from "react";
import axios from "axios";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { toast } from "react-toastify";
import { useAppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";

const AddStockPage = () => {
  const { colors } = useAppContext();
  const [formData, setFormData] = useState({
    name: "",
    fullName: "",
    url: "",
  });
  const navigation = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosPrivate.post("/admin/stocks/add", formData);
      console.log(res.data); // Handle success response
      navigation("/admin/stocks");
      // Optionally, reset form data after successful submission
      setFormData({
        name: "",
        fullName: "",
        url: "",
      });
    } catch (error) {
      toast.error("Please fill form properly");
      console.error("Error adding stock:", error);
      // Handle error
    }
  };

  return (
    <div
      className={`${colors.cardColor} max-w-md mx-auto mt-10 p-6  rounded-md shadow-md`}
    >
      <h2 className="text-lg font-semibold mb-4">Add Stock</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Stock Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`${colors.inputBackground} mt-1 p-2 ${colors.border} rounded-md w-full focus:outline-none focus:ring focus:ring-blue-200`}
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="fullName"
            className="block text-sm font-medium text-gray-700"
          >
            Stock Full Name
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className={`${colors.inputBackground} mt-1 p-2 ${colors.border} rounded-md w-full focus:outline-none focus:ring focus:ring-blue-200`}
          />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          >
            Add Stock
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddStockPage;
