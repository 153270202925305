import React, { useEffect, useState } from "react";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { useAppContext } from "../../context/AppContext";
import { Link, useNavigate } from "react-router-dom";

function StockListPage() {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { colors } = useAppContext();
  const navigation = useNavigate();

  useEffect(() => {
    fetchStocks();
  }, []);

  async function fetchStocks() {
    try {
      setLoading(true);
      const result = await axiosPrivate.get("/admin/stocks/list");
      setStocks(result.data.payload);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching stocks:", error);
      setLoading(false);
    }
  }

  async function handleStatusChange(stockId, newStatus) {
    try {
      await axiosPrivate.post(`/admin/stocks/update`, {
        id: stockId,
        status: newStatus,
      });
      // Update the status of the stock locally
      setStocks((prevStocks) =>
        prevStocks.map((stock) =>
          stock._id === stockId ? { ...stock, status: newStatus } : stock
        )
      );
    } catch (error) {
      console.error("Error updating stock status:", error);
    }
  }

  return (
    <div
      className={`${colors.background} min-h-screen py-8 px-4 md:px-12 lg:px-24 xl:px-32`}
    >
      <div className="flex justify-between mx-4">
        <div>
          <h1 className="text-3xl font-bold text-center mb-8">Stocks</h1>
        </div>
        <div>
          <button
            onClick={() => {
              navigation("/admin/stocks/add");
            }}
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          >
            Add Stock
          </button>
        </div>
      </div>

      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <table className={`${colors.cardColor} w-full`}>
          <thead className={`${colors.cardColor}`}>
            <tr>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Full Name</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody className="border  divide-y divide-black-700">
            {stocks.map((stock, index) => (
              <tr key={stock._id} className={``}>
                <td className={`${colors.borderColor} px-4 py-2`}>
                  <Link to={`/admin/stocks/${stock._id}`}>{stock.name}</Link>
                </td>
                <td className={`${colors.borderColor} px-4 py-2`}>
                  {stock.fullName}
                </td>
                <td className={`${colors.borderColor} px-4 py-2`}>
                  {stock.status}
                </td>
                <td className={`${colors.borderColor} text-center px-4 py-2`}>
                  {stock.status === "Active" ? (
                    <button
                      className=" bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleStatusChange(stock._id, "Inactive")}
                    >
                      Deactivate
                    </button>
                  ) : (
                    <button
                      className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleStatusChange(stock._id, "Active")}
                    >
                      Activate
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default StockListPage;
