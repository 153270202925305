// Dashboard.js

import React, { useEffect, useState } from "react";
import { AppConfig } from "../../Config";
import FileList from "./FileList";

const FilesPage = () => {
  useEffect(() => {
    document.title = `Users | ${AppConfig.APP_NAME}`;
  }, []);
  return (
    <div className="mt-4">
      <FileList />
    </div>
  );
};

export default FilesPage;
