import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { useAppContext } from "../../context/AppContext";

const CountryList = () => {
  const [countries, setCountries] = useState([]);
  const { colors } = useAppContext();

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await axiosPrivate.get("/admin/countries");
      setCountries(response.data.countries);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  return (
    <div className={`mt-8 mx-4 ${colors.cardColor}`}>
      <div className="mt-8 flex justify-between ">
        <h2 className={` px-4 pt-6 text-2xl font-semibold mb-4 ${colors.text}`}>
          Country List
        </h2>
        <Link to="/admin/countries/add">
          <button
            className={`${colors.button} text-white font-bold py-2 px-4 rounded mt-4 mx-4 hover:bg-blue-800`}
          >
            Add Country
          </button>
        </Link>
      </div>

      <table
        className={`min-w-full divide-y divide-gray-200 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg ${colors.border}`}
      >
        <thead className={`${colors.cardColor}`}>
          <tr>
            <th
              scope="col"
              className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${colors.secondaryText}`}
            >
              Name
            </th>
            <th
              scope="col"
              className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${colors.secondaryText}`}
            >
              Code
            </th>
            <th
              scope="col"
              className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${colors.secondaryText}`}
            >
              Amount
            </th>
            <th
              scope="col"
              className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${colors.secondaryText}`}
            >
              Status
            </th>
            <th
              scope="col"
              className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${colors.secondaryText}`}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className=" divide-y divide-gray-200">
          {countries.map((country) => (
            <tr key={country._id}>
              <td className={`px-6 py-4 whitespace-nowrap ${colors.text}`}>
                {country.name}
              </td>
              <td className={`px-6 py-4 whitespace-nowrap ${colors.text}`}>
                {country.code}
              </td>
              <td className={`px-6 py-4 whitespace-nowrap ${colors.text}`}>
                {country.amount}
              </td>
              <td className={`px-6 py-4 whitespace-nowrap ${colors.text}`}>
                {country.status ? "Active" : "Inactive"}
              </td>
              <td
                className={`px-6 py-4 whitespace-nowrap text-right text-sm font-medium`}
              >
                <Link to={`/admin/countries/edit/${country._id}`}>
                  <button
                    className={`${colors.button} text-white font-bold py-2 px-4 rounded hover:bg-blue-800`}
                  >
                    Edit
                  </button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CountryList;
