import React, { useState, useEffect } from "react";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { useAppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
const PostList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { colors } = useAppContext();
  const [refresh, setRefresh] = useState(new Date());
  const [selectedStatus, setSelectedStatus] = useState("custom");
  useEffect(() => {
    fetchposts();
  }, [currentPage, searchQuery, refresh, selectedStatus]);

  const fetchposts = async () => {
    try {
      setLoading(true);
      const response = await axiosPrivate.get(
        `/admin/posts?page=${currentPage}&name=${searchQuery}&status=${selectedStatus}`
      );
      const data = response.data;
      setPosts(data.posts);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const updatePostStatus = async ({ postId, action }) => {
    try {
      await axiosPrivate.post("/admin/posts/update_status", { postId, action });
      toast(action + " success");
    } catch (error) {
      toast(action + " Failed");
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-semibold mb-4">Posts List</h1>
      <div className="mb-4 flex items-center">
        <input
          type="text"
          placeholder="Search by username..."
          className={` ${colors.cardColor} border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm w-64 px-3 py-2 mr-2`}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded"
          onClick={() => fetchposts()}
        >
          Search
        </button>

        <div className="relative mx-2 items-center">
          <select
            onChange={(e) => {
              setSelectedStatus(e.target.value);
            }}
            value={selectedStatus}
            className="block w-full py-2 pl-3 pr-10 mt-1 text-white bg-gray-800 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <option value="custom">Custom</option>
            <option value="Active">Active</option>
            <option value="Suspended">Suspended</option>
            <option value="Deleted">Deleted</option>
          </select>
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table className={` min-w-full divide-y divide-gray-500`}>
            <thead className={`${colors.cardColor}`}>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Username
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Post
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Text
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Tags
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Likes
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Comments
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Approve
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Reject
                </th>
              </tr>
            </thead>
            <tbody className={`${colors.cardColor} divide-y divide-black-700 `}>
              {posts.map((post) => (
                <tr key={post._id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {post.userId.username}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {post.mediaType === "photo" ? (
                      <img
                        src={post.mediaUrl}
                        style={{ width: 200, height: 200 }}
                      />
                    ) : (
                      <video
                        src={post.mediaUrl}
                        style={{ width: 200, height: 200 }}
                      />
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{post.text}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {JSON.stringify(post.tags)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {post.likesCount}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {post.commentsCount}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(post.createdAt).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {post.isVerified}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap space-x-2">
                    <button
                      onClick={() => {
                        updatePostStatus({
                          postId: post._id,
                          action: "Approve",
                        });
                      }}
                      className="mx-1 px-3 py-1 rounded focus:outline-none bg-green-500 text-white"
                    >
                      Approve
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap space-x-2">
                    <button
                      onClick={() => {
                        updatePostStatus({
                          postId: post._id,
                          action: "Reject",
                        });
                      }}
                      className="mx-1 px-3 py-1 rounded focus:outline-none bg-green-500 text-white"
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500">
                Showing {posts.length} of {totalPages} pages
              </p>
            </div>
            <div className="flex">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <button
                    key={page}
                    onClick={() => handlePaginationClick(page)}
                    className={`mx-1 px-3 py-1 rounded focus:outline-none ${
                      currentPage === page
                        ? "bg-blue-500 text-white"
                        : "bg-white text-blue-500 hover:bg-blue-100"
                    }`}
                  >
                    {page}
                  </button>
                )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PostList;
