import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

function UsersGraph({ userGraph }) {
  return (
    <ResponsiveContainer width={"100%"} height={400}>
      <BarChart height={250} data={userGraph}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="count" name={"Daily Joined Users"} fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default UsersGraph;
