// Dashboard.js

import React, { useEffect, useState } from "react";
import { AppConfig } from "../../Config";
import DashboardStats from "./DashboardStats";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { useAppContext } from "../../context/AppContext";

const AdminDashboard = () => {
  const { user } = useAppContext();
  useEffect(() => {
    document.title = `Home | ${AppConfig.APP_NAME}`;
    fetchStats();
  }, []);

  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchStats = async () => {
    try {
      setLoading(true);
      const response = await axiosPrivate.get(`/admin/dashboard-detail`);
      const data = response.data;
      setStats(data.payload);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  return (
    <div className="mt-4 mx-4">{stats && <DashboardStats stats={stats} />}</div>
  );
};

export default AdminDashboard;
