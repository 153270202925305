import React from "react";
import { useAppContext } from "../../context/AppContext";
import UsersGraph from "./UsersGraph";

const DashboardStats = ({ stats }) => {
  const { colors } = useAppContext();
  return (
    <div className="container my-10">
      <UsersGraph userGraph={stats.dailyJoined.slice(0, 30)} />

      <div className=" grid grid-cols-1 md:grid-cols-4 gap-6">
        {/* User Stats */}
        <div className="col-span-3">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div>
              <div
                className={`${colors.cardColor} p-6 rounded-lg shadow-md mb-4`}
              >
                <h3 className="text-lg font-semibold mb-2 text-white">
                  Total Users
                </h3>
                <p className="text-xl font-semibold text-white">
                  {stats.userStats.totalUsers}
                </p>
              </div>
            </div>
            <div>
              <div
                className={`${colors.cardColor} p-6 rounded-lg shadow-md mb-4`}
              >
                <h3 className="text-lg font-semibold mb-2 text-white">
                  Active Users
                </h3>
                <p className="text-xl font-semibold text-white">
                  {stats.userStats.activeUsers}
                </p>
              </div>
            </div>
            <div>
              <div
                className={`${colors.cardColor} p-6 rounded-lg shadow-md mb-4`}
              >
                <h3 className="text-lg font-semibold mb-2 text-white">
                  Today's Joined Users
                </h3>
                <p className="text-xl font-semibold text-white">
                  {stats.userStats.todayJoinedUsers}
                </p>
              </div>
            </div>
            <div>
              <div
                className={`${colors.cardColor} p-6 rounded-lg shadow-md mb-4`}
              >
                <h3 className="text-lg font-semibold mb-2 text-white">
                  Yesterday's Joined Users
                </h3>
                <p className="text-xl font-semibold text-white">
                  {stats.userStats.yesterdayJoinedUsers}
                </p>
              </div>
            </div>
            <div>
              <div
                className={`${colors.cardColor} p-6 rounded-lg shadow-md mb-4`}
              >
                <h3 className="text-lg font-semibold mb-2 text-white">
                  This Week's Joined Users
                </h3>
                <p className="text-xl font-semibold text-white">
                  {stats.userStats.thisWeekJoinedUsers}
                </p>
              </div>
            </div>
            <div>
              <div
                className={`${colors.cardColor} p-6 rounded-lg shadow-md mb-4`}
              >
                <h3 className="text-lg font-semibold mb-2 text-white">
                  This Month's Joined Users
                </h3>
                <p className="text-xl font-semibold text-white">
                  {stats.userStats.thisMonthJoinedUsers}
                </p>
              </div>
            </div>
            <div>
              <div
                className={`${colors.cardColor} p-6 rounded-lg shadow-md mb-4`}
              >
                <h3 className="text-lg font-semibold mb-2 text-white">
                  Last Month's Joined Users
                </h3>
                <p className="text-xl font-semibold text-white">
                  {stats.userStats.lastMonthJoinedUsers}
                </p>
              </div>
            </div>
            <div>
              <div
                className={`${colors.cardColor} p-6 rounded-lg shadow-md mb-4`}
              >
                <h3 className="text-lg font-semibold mb-2 text-white">
                  This Year's Joined Users
                </h3>
                <p className="text-xl font-semibold text-white">
                  {stats.userStats.thisYearJoinedUsers}
                </p>
              </div>
            </div>
            <div>
              <div
                className={`${colors.cardColor} p-6 rounded-lg shadow-md mb-4`}
              >
                <h3 className="text-lg font-semibold mb-2 text-white">
                  Last Year's Joined Users
                </h3>
                <p className="text-xl font-semibold text-white">
                  {stats.userStats.lastYearJoinedUsers}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Files and Size */}
        <div className={`${colors.cardColor} p-6 rounded-lg shadow-md`}>
          <h2 className="text-lg font-semibold mb-4 text-white">
            Files and Size
          </h2>
          <div className="flex flex-col">
            <div className="flex justify-between mb-4">
              <p className="text-gray-400">Active Files:</p>
              <p className="text-xl font-semibold text-white">
                {stats.filesAndSize.activeFiles}
              </p>
            </div>
            <div className="flex justify-between mb-4">
              <p className="text-gray-400">Suspended Files:</p>
              <p className="text-xl font-semibold text-white">
                {stats.filesAndSize.suspendedFiles}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-400">Total Size:</p>
              <p className="text-xl font-semibold text-white">
                {stats.filesAndSize.totalSize}
              </p>
            </div>
          </div>
        </div>

        {/* Expenses */}
        <div className={`${colors.cardColor} p-6 rounded-lg shadow-md`}>
          <h2 className="text-lg font-semibold mb-4 text-white">Expenses</h2>
          <div className="flex flex-col">
            <div className="flex justify-between mb-4">
              <p className="text-gray-400">Pending:</p>
              <p className="text-xl font-semibold text-white">
                {stats.expenses.Pending}
              </p>
            </div>
            <div className="flex justify-between mb-4">
              <p className="text-gray-400">Approved:</p>
              <p className="text-xl font-semibold text-white">
                {stats.expenses.Approved}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-400">Paid:</p>
              <p className="text-xl font-semibold text-white">
                {stats.expenses.Paid}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardStats;
