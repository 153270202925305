import React, { useState, useEffect } from "react";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { useAppContext } from "../../context/AppContext";
import { toast } from "react-toastify";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { colors } = useAppContext();
  const [refresh, setRefresh] = useState(new Date());
  useEffect(() => {
    fetchUsers();
  }, [currentPage, searchQuery, refresh]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axiosPrivate.get(
        `/admin/users?page=${currentPage}&name=${searchQuery}`
      );
      const data = response.data;
      setUsers(data.payload.data);
      setTotalPages(data.payload.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getUserStatus = (status) => {
    if (status === 0) {
      return "Verification Pending";
    } else if (status === 1) {
      return "Verified";
    } else if (status === 2) {
      return "Suspended";
    } else if (status === 3) {
      return "Deleted";
    }
  };

  const updateUserStatus = async (userId, status) => {
    if (status === 0) {
      status = 1;
    } else if (status === 1) {
      status = 2;
    } else if (status === 2) {
      status = 1;
    }
    const result = await axiosPrivate.post("/admin/users/update-status", {
      userId,
      status,
    });
    setRefresh(new Date());
  };

  const getBanButtonText = (status) => {
    if (status === 0) {
      return "Approve";
    }
    if (status === 1) {
      return "Ban";
    }
    if (status === 2) {
      return "Unban";
    }
  };

  const changeAutoPostVerificationStatus = async ({
    userId,
    autoPostVerification,
  }) => {
    try {
      const result = await axiosPrivate.post("/admin/user/enable_auto_post", {
        userId,
        autoPostVerification,
      });
      setRefresh(new Date());
      toast("Success");
    } catch (error) {
      toast("Failed");
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-semibold mb-4">User List</h1>
      <div className="mb-4 flex items-center">
        <input
          type="text"
          placeholder="Search by username..."
          className={` ${colors.cardColor} border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm w-64 px-3 py-2 mr-2`}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded"
          onClick={() => fetchUsers()}
        >
          Search
        </button>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table className={` min-w-full divide-y divide-gray-500`}>
            <thead className={`${colors.cardColor}`}>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Username
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  User Role
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Post Verify
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Posts
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Join Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className={`${colors.cardColor} divide-y divide-black-700 `}>
              {users.map((user) => (
                <tr key={user._id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.username}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.userRole === "n" ? "Normal" : "Admin"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.autoPostVerification ? "Auto" : "Manual"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.totalPosts}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(user.createdAt).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {getUserStatus(user.status)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap space-x-2">
                    {user.status !== 3 && (
                      <>
                        <button
                          onClick={() => {
                            changeAutoPostVerificationStatus({
                              autoPostVerification: !user.autoPostVerification,
                              userId: user._id,
                            });
                          }}
                          className={`mx-1 px-3 py-1 rounded focus:outline-none  text-white ${
                            user.autoPostVerification
                              ? "bg-blue-500"
                              : "bg-green-500"
                          }`}
                        >
                          {user.autoPostVerification
                            ? "Disable Auto Post"
                            : "Enable Auto Post"}
                        </button>
                        <button className="mx-1 px-3 py-1 rounded focus:outline-none bg-green-500 text-white">
                          View
                        </button>
                        <button
                          onClick={() => {
                            updateUserStatus(user._id, user.status);
                          }}
                          className="mx-1 px-3 py-1 rounded focus:outline-none bg-blue-500 text-white"
                        >
                          {getBanButtonText(user.status)}
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500">
                Showing {users.length} of {totalPages} pages
              </p>
            </div>
            <div className="flex">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <button
                    key={page}
                    onClick={() => handlePaginationClick(page)}
                    className={`mx-1 px-3 py-1 rounded focus:outline-none ${
                      currentPage === page
                        ? "bg-blue-500 text-white"
                        : "bg-white text-blue-500 hover:bg-blue-100"
                    }`}
                  >
                    {page}
                  </button>
                )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserList;
