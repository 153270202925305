// Dashboard.js

import React, { useEffect, useState } from "react";
import { AppConfig } from "../../Config";
import UserList from "./UserList";

const Userspage = () => {
  useEffect(() => {
    document.title = `Users | ${AppConfig.APP_NAME}`;
  }, []);
  return (
    <div className="mt-4">
      <UserList />
    </div>
  );
};

export default Userspage;
