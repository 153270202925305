export const ThemeColors = {
  dark: {
    background: "bg-gray-900",
    cardColor: "bg-gray-800",
    text: "text-white",
    secondaryText: "text-gray-400", // Added secondaryText class
    border: "border-gray-600",
    checkbox: "text-gray-300",
    button: "bg-blue-700 hover:bg-blue-800",
    inputBackground: "bg-gray-700 text-white",
    classPrimaryText: "text-blue-500",
    btnColorPrimary: "#008080",
    btnColorSecondary: "#e67e22",
  },
  light: {
    cardColor: "bg-white",
    background: "bg-gray-200",
    text: "text-black",
    secondaryText: "text-gray-600", // Added secondaryText class
    border: "border-gray-300",
    checkbox: "text-gray-600",
    button: "bg-blue-500 hover:bg-blue-600",
    inputBackground: "bg-gray-200 text-black",
    btnColorPrimary: "#3498db",
    btnColorSecondary: "#e67e22",
    classPrimaryText: "text-blue-500",
  },
};
