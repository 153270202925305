// Dashboard.js

import React, { useEffect, useState } from "react";
import { AppConfig } from "../../Config";
import PostList from "./PostList";

const PostsPage = () => {
  useEffect(() => {
    document.title = `Posts | ${AppConfig.APP_NAME}`;
  }, []);
  return (
    <div className="mt-4">
      <PostList />
    </div>
  );
};

export default PostsPage;
