import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { useAppContext } from "../../context/AppContext";

const CountryAdd = () => {
  const { colors } = useAppContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const [country, setCountry] = useState({
    name: "",
    code: "",
    amount: 0,
    status: true,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCountry({ ...country, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosPrivate.post(`/admin/country/add`, country);
      navigate("/admin/countries");
    } catch (error) {
      console.error("Error updating country:", error);
    }
  };

  return (
    <div className={`mt-4 mx-4 ${colors.cardColor} p-4 rounded-lg shadow-md`}>
      <h2 className={`text-xl font-semibold mb-4 ${colors.text}`}>
        Add Country
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label
              className={`block text-sm font-medium ${colors.secondaryText}`}
            >
              Name
            </label>
            <input
              type="text"
              name="name"
              value={country.name}
              onChange={handleChange}
              className={`mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${colors.inputBackground} py-3 px-4`}
            />
          </div>
          <div>
            <label
              className={`block text-sm font-medium ${colors.secondaryText}`}
            >
              Code
            </label>
            <input
              type="text"
              name="code"
              value={country.code}
              onChange={handleChange}
              className={`mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${colors.inputBackground} py-3 px-4`}
            />
          </div>
          <div>
            <label
              className={`block text-sm font-medium ${colors.secondaryText}`}
            >
              Amount
            </label>
            <input
              type="number"
              name="amount"
              value={country.amount}
              onChange={handleChange}
              className={`mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${colors.inputBackground} py-3 px-4`}
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="status"
              checked={country.status}
              onChange={(e) =>
                setCountry({ ...country, status: e.target.checked })
              }
              className={`form-checkbox h-5 w-5 text-${colors.checkbox} transition duration-150 ease-in-out`}
            />
            <label className={`ml-2 ${colors.secondaryText}`}>Active</label>
          </div>
        </div>
        <button
          type="submit"
          className={`w-full py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white ${colors.button} focus:outline-none focus:border-${colors.btnColorSecondary} focus:shadow-outline-${colors.btnColorSecondary} active:bg-${colors.btnColorSecondary} transition ease-in-out duration-150`}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CountryAdd;
