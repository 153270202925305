import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { useAppContext } from "../../context/AppContext";
import AnalysisData from "./components/AnalysisData";
import moment from "moment";
import LineGraph from "./components/LineGraph";
import LineGraphReturns from "./components/LineGraphReturns";
import LineGraphForGroup from "./components/LineGrapForGroup";

function ViewStock() {
  const { colors } = useAppContext();
  const { id } = useParams();
  const [stocks, setStocks] = useState(null);
  const [records, setRecords] = useState([]);
  const [file, setFile] = useState(null);
  const [sevenDayAnalysis, setSevenDayAnalysis] = useState(null);
  const [fifteenDayAnalysis, setFifteenDayAnalysis] = useState(null);
  const [thirtyDayAnalysis, setThirtyDayAnalysis] = useState(null);
  const [daily60DayGraph, setDaily60DayGraph] = useState(null);

  const [sevenDayGraphData, setSevenDayGraphData] = useState(null);
  const [fifteenDayGraphData, setFifteenDayGraphData] = useState(null);
  const [thirtyDayGraphData, setThirtyDayGraphData] = useState(null);

  useEffect(() => {
    fetchStock();
  }, []);

  const fetchStock = async () => {
    const result = await axiosPrivate.get("/admin/stocks/" + id);
    const data = result.data.payload;
    console.log(result.data.payload);
    setStocks(result.data.payload.stock);
    setRecords(result.data.payload.records);
    setSevenDayAnalysis(data.sevenDayData);
    setFifteenDayAnalysis(data.fifteenDayData);
    setThirtyDayAnalysis(data.thirtyDayData);

    generateGraphData(data.records);

    const sevenDayGraphData = generateRangeDateGraph(data.sevenDayData);
    const fifteenDayGraphData = generateRangeDateGraph(data.fifteenDayData);
    const thirtyDayGraphData = generateRangeDateGraph(data.thirtyDayData);
    setSevenDayGraphData(sevenDayGraphData);
    setFifteenDayGraphData(fifteenDayGraphData);
    setThirtyDayGraphData(thirtyDayGraphData);
    console.log({ sevenDayGraphData });
  };

  const generateRangeDateGraph = (listTemp) => {
    let list = Object.entries(listTemp);

    let graphList = [];
    let i = 1;
    for (let row of list) {
      let item = row[1];
      let temp = {};
      temp.name = `${moment(item.endDate).format("DD,MMM")}-${moment(
        item.date
      ).format("DD,MMM")}`;
      temp.avgReturn = item.avgReturn;
      temp.highestPercentage = item.highest;
      temp.highestPrice = item.highestPrice;
      temp.lowestPrice = item.lowestPrice;
      graphList.push(temp);
      i++;
      if (i > 30) {
        break;
      }
    }
    return graphList.reverse();
  };

  const generateGraphData = (list) => {
    let listTemp = [];
    let i = 1;
    for (let item of list) {
      i++;
      let temp = {};
      temp.name = moment(item.date).format("DD/MM");
      temp.open = item.open;
      temp.high = item.high;
      temp.highMinusOpen = item.highMinusOpen;
      temp.low = item.low;
      temp.dailyProfitPercentage = item.dailyProfitPercentage;
      temp.openMinusLow = (item.low - item.open).toFixed(2);
      console.log(temp.openMinusLow);
      listTemp.push(temp);

      if (i > 29) {
        break;
      }
    }
    setDaily60DayGraph(listTemp.reverse());
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("stockId", stocks._id);

      const response = await axiosPrivate.post(
        "/admin/stocks/import",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("File uploaded successfully:", response.data);
      // You may want to update the stock list after uploading the file
      fetchStock();
      setFile(null);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    console.log(stocks);
    document.title = stocks?.fullName || "";
  }, [stocks]);

  return (
    <div className="mx-2 mt-4">
      <div>
        {stocks && (
          <StockCard stock={stocks} handleFileChange={handleFileChange} />
        )}
      </div>

      <div className="container m-auto text-xl">
        {daily60DayGraph && (
          <LineGraph title={"Daily 60 Days"} data={daily60DayGraph} />
        )}

        {daily60DayGraph && (
          <LineGraphReturns title={"Daily 60 Returns"} data={daily60DayGraph} />
        )}

        <hr className="mt-20" />

        {sevenDayGraphData && (
          <LineGraphForGroup data={sevenDayGraphData} title={"Per 7 Days"} />
        )}
        {fifteenDayGraphData && (
          <LineGraphForGroup data={fifteenDayGraphData} title={"Per 15 Days"} />
        )}

        {thirtyDayGraphData && (
          <LineGraphForGroup data={thirtyDayGraphData} title={"Per 30 Days"} />
        )}

        <hr className="mt-20" />

        {sevenDayAnalysis && (
          <AnalysisData data={sevenDayAnalysis} title={"7 days Analysis"} />
        )}
        {fifteenDayAnalysis && (
          <AnalysisData data={fifteenDayAnalysis} title={"15 Day Analysis"} />
        )}

        {thirtyDayAnalysis && (
          <AnalysisData data={thirtyDayAnalysis} title={"30 Day Analysis"} />
        )}
      </div>
      <div className=" container m-auto mt-10 mb-20">
        <RecordTable stocks={records.slice(0, 20)} />
      </div>
    </div>
  );
}
const StockCard = ({ stock, handleFileChange }) => {
  const { colors } = useAppContext();

  return (
    <div
      className={[
        `${colors.cardColor} ${colors.primaryText} max-w-3xl mx-auto  shadow-lg rounded-lg overflow-hidden`,
      ]}
    >
      <div className="flex justify-between gap-4 px-4 py-2">
        <h1 className=" font-bold text-xl whitespace-nowrap">{stock.name} </h1>
        <p className="  font-bold  mb-1 text-sm ">{stock.fullName} </p>

        <div>
          <input type="file" name="csvfile" onChange={handleFileChange} />
        </div>
      </div>
    </div>
  );
};

const RecordTable = ({ stocks }) => {
  const { colors } = useAppContext();
  return (
    <div className="overflow-x-auto">
      <table
        className={`table-auto text-center w-full border-collapse border border-gray-800 ${colors.cardColor}`}
      >
        <thead>
          <tr className={colors.background}>
            <th className="px-4 py-2">Date</th>
            <th className="px-4 py-2">Open</th>
            <th className="px-4 py-2">Return%</th>
            <th className="px-4 py-2">Close</th>
            <th className="px-4 py-2">Close-Open</th>
            <th className="px-4 py-2">Low-Open</th>
            <th className="px-4 py-2">High-Open</th>
            <th className="px-4 py-2">Low</th>
            <th className="px-4 py-2">High</th>
            <th className="px-4 py-2">High-Low</th>
            <th className="px-4 py-2">52W High</th>
            <th className="px-4 py-2">52W Low</th>
          </tr>
        </thead>
        <tbody>
          {stocks.map((stock) => (
            <tr
              key={stock._id}
              className={`${
                new Date(stock.date).toDateString().includes("Mon")
                  ? "bg-green-900"
                  : ""
              }  border-b border-gray-600`}
            >
              <td className="px-4 py-2">
                {new Date(stock.date).toDateString()}
              </td>
              <td className="px-4 py-2">{stock.open}</td>
              <td className="px-4 py-2">{stock.dailyProfitPercentage}%</td>
              <td className="px-4 py-2">{stock.close}</td>
              <td className="px-4 py-2">{stock.closeMinusOpen}</td>
              <td className="px-4 py-2">
                {(stock.low - stock.open).toFixed(2)}
              </td>
              <td className="px-4 py-2">
                {(stock.high - stock.open).toFixed(2)}
              </td>
              <td className="px-4 py-2">{stock.low}</td>
              <td className="px-4 py-2">{stock.high}</td>
              <td className="px-4 py-2">{stock.highMinusLow}</td>
              <td className="px-4 py-2">{stock.high52}</td>
              <td className="px-4 py-2">{stock.low52}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewStock;
