import React, { useState, useEffect } from "react";
import { axiosPrivate } from "../../lib/AxiosInstance";
import { useAppContext } from "../../context/AppContext";
import moment from "moment";

const FileList = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { colors } = useAppContext();
  const [refresh, setRefresh] = useState(new Date());
  const [sortBy, setSortBy] = useState("createdAt"); // Default sort by createdAt
  const [sortOrder, setSortOrder] = useState("desc"); // Default sort order is descending

  useEffect(() => {
    fetchFiles();
  }, [currentPage, searchQuery, refresh, sortBy, sortOrder]);

  const fetchFiles = async () => {
    try {
      setLoading(true);
      const response = await axiosPrivate.get(
        `/admin/files?page=${currentPage}&filename=${searchQuery}&sort=${sortBy}&order=${sortOrder}`
      );
      const data = response.data;
      setFiles(data.payload.files);
      setTotalPages(data.payload.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching files:", error);
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = (e) => {
    const { value } = e.target;
    setSortBy(value);
  };

  const handleOrderChange = (e) => {
    const { value } = e.target;
    setSortOrder(value);
  };

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const changeFileStatus = async (fileId, status) => {
    try {
      const result = await axiosPrivate.post("/admin/files/status", {
        fileId,
        status,
      });
      setRefresh(new Date());
    } catch (error) {}
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-semibold mb-4">File List</h1>
      <div className="mb-4 flex items-center space-x-4">
        <input
          type="text"
          placeholder="Search by filename..."
          className={` ${colors.cardColor} border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm w-64 px-3 py-2`}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <select
          className={` ${colors.cardColor} border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm px-3 py-2`}
          value={sortBy}
          onChange={handleSortChange}
        >
          <option value="createdAt">Created Date</option>
          <option value="filesize">Filesize</option>
          <option value="view">Views</option>
        </select>
        <select
          className={` ${colors.cardColor} border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm px-3 py-2`}
          value={sortOrder}
          onChange={handleOrderChange}
        >
          <option value="desc">Descending</option>
          <option value="asc">Ascending</option>
        </select>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded"
          onClick={() => fetchFiles()}
        >
          Search
        </button>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {files.length > 0 ? (
            <table className={`min-w-full divide-y divide-gray-500`}>
              <thead className={`${colors.cardColor}`}>
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    User
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Filename
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Views
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Reports
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Filesize
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Created At
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody
                className={`${colors.cardColor} divide-y divide-black-700`}
              >
                {files.map((file) => (
                  <tr key={file._id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {file.userId?.username}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap ">
                      {file.tempLink ? (
                        <a
                          href={file.tempLink}
                          target="_blank"
                          className="text-blue-500 hover:text-blue-700"
                        >
                          {file.fileName}
                        </a>
                      ) : (
                        file.fileName
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {file.visitCount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {file.reportCount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {(file.filesize / (1024 * 1024)).toFixed(2)} MB
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {file.status}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {moment(file.createdAt).format("DD/MM/YYYY")}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      {file.status === "Active" && (
                        <button
                          className={`mx-1 px-3 py-1 rounded focus:outline-none bg-blue-500 text-white hover:bg-blue-100`}
                          onClick={() => {
                            changeFileStatus(file._id, "suspend");
                          }}
                        >
                          Ban
                        </button>
                      )}
                      {file.status === "Suspended" && (
                        <button
                          className={`mx-1 px-3 py-1 rounded focus:outline-none bg-blue-500 text-white hover:bg-blue-100`}
                          onClick={() => {
                            changeFileStatus(file._id, "unban");
                          }}
                        >
                          Unban
                        </button>
                      )}
                      {file.status === "Suspended" && (
                        <button
                          className={`mx-1 px-3 py-1 rounded focus:outline-none bg-red-500 text-white hover:bg-blue-100`}
                          onClick={() => {
                            changeFileStatus(file._id, "delete");
                          }}
                        >
                          Delete
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No files found.</p>
          )}
          {/* Pagination */}
          <div className="mt-4 flex justify-between items-center">
            <div>
              <p className="text-sm text-gray-500">
                Showing {files.length} of {totalPages} pages
              </p>
            </div>
            <div className="flex">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <button
                    key={page}
                    onClick={() => handlePaginationClick(page)}
                    className={`mx-1 px-3 py-1 rounded focus:outline-none ${
                      currentPage === page
                        ? "bg-blue-500 text-white"
                        : "bg-white text-blue-500 hover:bg-blue-100"
                    }`}
                  >
                    {page}
                  </button>
                )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FileList;
