// DayNightModeContext.js

import React, { createContext, useContext, useEffect, useState } from "react";
import { ThemeColors } from "../constants/Themes";

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppContextProvider = ({ children }) => {
  const [isDayMode, setIsDayMode] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setAutoLogin();
    if (window.innerWidth > 640) {
      setIsSidebarOpen(true);
    }
  }, []);

  const setAutoLogin = () => {
    const userId = localStorage.getItem("userId");
    const email = localStorage.getItem("email");
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const role = localStorage.getItem("role");
    if (userId && email && accessToken && refreshToken) {
      const payload = {
        userId,
        email,
        accessToken,
        refreshToken,
        role,
      };
      loginUser(payload);
    }
  };

  const loginUser = (payload) => {
    localStorage.setItem("userId", payload.userId);
    localStorage.setItem("email", payload.email);
    localStorage.setItem("accessToken", payload.accessToken);
    localStorage.setItem("refreshToken", payload.refreshToken);
    localStorage.setItem("role", payload.role);
    setUser(payload);
    return true;
  };

  const logOut = () => {
    localStorage.clear();
    setUser(null);
  };

  const [colors, setColors] = useState(
    isDayMode ? ThemeColors.light : ThemeColors.dark
  );
  useEffect(() => {
    if (isDayMode) {
      setColors(ThemeColors.dark);
    } else {
      setColors(ThemeColors.light);
    }
  }, [isDayMode]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleDayMode = () => {
    setIsDayMode((prevMode) => !prevMode);
  };

  const contextValue = {
    isDayMode,
    toggleDayMode,
    isSidebarOpen,
    toggleSidebar,
    colors,
    loginUser,
    logOut,
    user,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
