import moment from "moment";
import React from "react";

const AnalysisData = ({ data, title }) => {
  return (
    <div className="container mx-auto mt-4">
      <h1 className="text-3xl font-bold mb-6">{title}</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr className="text-center">
              <th className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                End Date
              </th>
              <th className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                Lowest(price)
              </th>
              <th className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                Highest(price)
              </th>
              <th className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                Average
              </th>
              <th className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                Avg Return
              </th>
              <th className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                Highest Return
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 text-center">
            {Object.values(data)
              .slice(0, 5)
              .map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-500" : "bg-gray-600"}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    {moment(item.date).format("ddd, DD/MMM/YYYY")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {moment(item.endDate).format("ddd, DD/MMM/YYYY")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.lowestPrice}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.highestPrice}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.avgPrice}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.avgReturn}%
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.highest}%
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AnalysisData;
