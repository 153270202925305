import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useAppContext } from "../../../context/AppContext";

function LineGraphReturns({ data, title }) {
  const { colors } = useAppContext();

  return (
    <div className={`${colors.cardColor} flex p-1 container w-full mt-6`}>
      <ResponsiveContainer width={"50%"} height={300}>
        <div className="mx-10 text-xl text-vold mb-2">{title}</div>
        <LineChart
          // width={1024}
          height={600}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="4 4" />
          <XAxis dataKey="name" />
          <YAxis domain={[-20, "auto"]} />
          <Tooltip contentStyle={{ color: "red" }} />
          <Legend />
          <Line
            type="linear"
            dataKey="dailyProfitPercentage"
            name="Profit %"
            stroke="#8884d8"
          />
          {/* <Line
            type="monotone"
            name="High - Open"
            dataKey="highMinusOpen"
            stroke="#82ca9d"
          /> */}
        </LineChart>
      </ResponsiveContainer>

      <ResponsiveContainer width={"50%"} height={300}>
        <div className="mx-10 text-xl text-vold mb-2">
          60 Day Price Diffrence
        </div>
        <LineChart
          // width={1024}
          height={600}
          data={data}
          desc="Daily Price Diffrence"
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis allowDataOverflow={true} domain={[-200, "auto"]} />
          <Tooltip contentStyle={{ color: "red" }} />
          <Legend />
          <Line
            type="monotone"
            name="High - Open (Price Diffrence)"
            dataKey="highMinusOpen"
            stroke="#82ca9d"
          />

          <Line
            type="monotone"
            name="Open-Low (Price Diffrence)"
            dataKey="openMinusLow"
            stroke="red"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default LineGraphReturns;
