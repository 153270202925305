import axios from "axios";
import { AppConfig } from "../Config";

export const axiosInstance = axios.create({
  baseURL: AppConfig.BACKEND_URL + "/api",
});

export const axiosPrivate = axios.create({
  baseURL: AppConfig.BACKEND_URL + "/api",
});

axiosPrivate.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
