import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAppContext } from "../../context/AppContext";
import { axiosInstance } from "../../lib/AxiosInstance";
import { BackendRoutes } from "../../constants/BackendRoute";
import { Link, useNavigate } from "react-router-dom";
import { AppConfig } from "../../Config";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const { colors } = useAppContext();
  const navigation = useNavigate();
  const { loginUser, user } = useAppContext();

  useEffect(() => {
    document.title = `Register an account | ${AppConfig.APP_NAME}`;
  }, []);

  useEffect(() => {
    if (user) {
      navigation("/", { replace: true });
    }
  }, [user]);

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post(BackendRoutes.Register, {
        username,
        email,
        password,
      });

      // Assuming the response has the structure you provided
      const { status, msg, payload } = response.data;

      if (status === 200) {
        // Registration successful
        console.log(msg);
        console.log(payload);
        loginUser(payload);
        // if (loginUser(payload)) {
        //   navigation("/", { replace: true });
        // }

        // TODO: Store the user data (userId, email, accessToken, refreshToken) in your app state
        // You can use the context or any state management solution for this
      } else {
        // Registration failed
        console.error(msg);

        // Handle form validation errors
        if (payload) {
          setFormErrors(payload);
        }
      }
    } catch (error) {
      console.error("Error:", error);

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Server Response Data:", error.response.data);
        console.error("Server Response Status:", error.response.status);
        console.error("Server Response Headers:", error.response.headers);

        // Update the formErrors state based on the error response
        if (error.response.data && error.response.data.payload) {
          setFormErrors(error.response.data.payload);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No Response Received");
        console.error("Request Config:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error Message:", error.message);
      }
    }
  };

  return (
    <div
      className={`flex items-center justify-center h-screen ${colors.background}`}
    >
      <div
        className={`max-w-md w-full ${colors.cardColor} p-8 rounded-lg shadow-md`}
      >
        <h2 className={`text-2xl font-semibold mb-6 ${colors.text}`}>
          Register
        </h2>

        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className={`block text-sm font-medium ${colors.text}`}
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              placeholder="Username"
              className={`mt-1 p-2 w-full border rounded-md ${
                colors.inputBackground
              } ${colors.border} ${
                formErrors.username ? "border-red-500" : ""
              }`}
              onChange={(e) => setUsername(e.target.value)}
            />
            {formErrors.username && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.username.msg}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className={`block text-sm font-medium ${colors.text}`}
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              placeholder="Email"
              className={`mt-1 p-2 w-full border rounded-md ${
                colors.inputBackground
              } ${colors.border} ${formErrors.email ? "border-red-500" : ""}`}
              onChange={(e) => setEmail(e.target.value)}
            />
            {formErrors.email && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.email.msg}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className={`block text-sm font-medium ${colors.text}`}
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              className={`mt-1 p-2 w-full border rounded-md ${colors.border} ${
                colors.inputBackground
              } ${formErrors.password ? "border-red-500" : ""}`}
              onChange={(e) => setPassword(e.target.value)}
            />
            {formErrors.password && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.password.msg}
              </p>
            )}
          </div>

          <button
            type="submit"
            className={`py-2 px-4 text-white rounded-md focus:outline-none focus:ring focus:border-blue-300 ${colors.button}`}
          >
            Register
          </button>
        </form>

        <div className={`mt-4 text-sm ${colors.text}`}>
          Already have an account?{" "}
          <Link
            to="/login"
            className={`text-blue-500 ${colors.classPrimaryText}`}
          >
            Login here
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
